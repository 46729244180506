<template>
  <base-layout-two
    page-title="Create New Property"
    page-default-back-link="/properties"
  >
    <div class="padding-8">
      <v-form @submit="onSubmit">
        <base-card title="Property Details">
          <ion-grid>
            <ion-row>
              <!-- Name -->
              <ion-col>
                <base-input label-text="Name *">
                  <v-field
                    name="name"
                    v-slot="{ field }"
                    v-model="property.name"
                    :rules="required"
                  >
                    <ion-input
                      name="name"
                      v-bind="field"
                      placeholder="Name"
                      type="text"
                      autocapitalize="words"
                      autocorrect
                    ></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="name" class="error-message">
                </v-error-message>
              </ion-col>
            </ion-row>

            <ion-row>
              <!-- Address Line 1 -->
              <ion-col>
                <base-input label-text="Address Line 1 *">
                  <v-field
                    name="address_line_1"
                    v-slot="{ field }"
                    v-model="property.address_line_1"
                    :rules="required"
                  >
                    <ion-input
                      v-bind="field"
                      type="text"
                      placeholder="Address Line 1"
                      autocapitalize="words"
                      autocorrect
                    ></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="address_line_1" class="error-message">
                </v-error-message>
              </ion-col>
            </ion-row>

            <ion-row>
              <!-- Address Line 2 -->
              <ion-col>
                <base-input label-text="Address Line 2">
                  <v-field
                    name="address_line_2"
                    v-slot="{ field }"
                    v-model="property.address_line_2"
                  >
                    <ion-input
                      v-bind="field"
                      type="text"
                      placeholder="Address Line 2"
                      autocapitalize="words"
                      autocorrect
                    ></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="address_line_2" class="error-message">
                </v-error-message>
              </ion-col>
            </ion-row>

            <ion-row>
              <!-- City -->
              <ion-col size="12" size-lg="5">
                <base-input label-text="City *">
                  <v-field
                    name="city"
                    v-slot="{ field }"
                    v-model="property.city"
                    :rules="required"
                  >
                    <ion-input
                      v-bind="field"
                      type="text"
                      placeholder="City"
                      autocapitalize="words"
                      autocorrect
                    ></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="city" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- State -->
              <ion-col size="12" size-lg="2">
                <base-input label-text="State *">
                  <v-field
                    name="state"
                    v-slot="{ field }"
                    v-model="property.state"
                    :rules="required"
                  >
                    <select v-bind="field" class="regular-select">
                      <option value="AL">AL</option>
                      <option value="AK">AK</option>
                      <option value="AZ">AZ</option>
                      <option value="AR">AR</option>
                      <option value="CA">CA</option>
                      <option value="CO">CO</option>
                      <option value="CT">CT</option>
                      <option value="DC">DC</option>
                      <option value="DE">DE</option>
                      <option value="FL">FL</option>
                      <option value="GA">GA</option>
                      <option value="HI">HI</option>
                      <option value="ID">ID</option>
                      <option value="IL">IL</option>
                      <option value="IN">IN</option>
                      <option value="IA">IA</option>
                      <option value="KS">KS</option>
                      <option value="KY">KY</option>
                      <option value="LA">LA</option>
                      <option value="ME">ME</option>
                      <option value="MD">MD</option>
                      <option value="MA">MA</option>
                      <option value="MI">MI</option>
                      <option value="MN">MN</option>
                      <option value="MS">MS</option>
                      <option value="MO">MO</option>
                      <option value="MT">MT</option>
                      <option value="NE">NE</option>
                      <option value="NV">NV</option>
                      <option value="NH">NH</option>
                      <option value="NJ">NJ</option>
                      <option value="NM">NM</option>
                      <option value="NY">NY</option>
                      <option value="NC">NC</option>
                      <option value="ND">ND</option>
                      <option value="OH">OH</option>
                      <option value="OK">OK</option>
                      <option value="OR">OR</option>
                      <option value="PA">PA</option>
                      <option value="RI">RI</option>
                      <option value="SC">SC</option>
                      <option value="SD">SD</option>
                      <option value="TN">TN</option>
                      <option value="TX">TX</option>
                      <option value="UT">UT</option>
                      <option value="VT">VT</option>
                      <option value="VA">VA</option>
                      <option value="WA">WA</option>
                      <option value="WV">WV</option>
                      <option value="WI">WI</option>
                      <option value="WY">WY</option>
                    </select>
                  </v-field>
                </base-input>
                <v-error-message name="state" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Zipcode -->
              <ion-col size="12" size-lg="5">
                <base-input label-text="Zipcode *">
                  <v-field
                    name="zipcode"
                    v-slot="{ field }"
                    v-model="property.zipcode"
                    :rules="required"
                  >
                    <ion-input
                      v-bind="field"
                      type="text"
                      placeholder="Zipcode"
                    ></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="zipcode" class="error-message">
                </v-error-message>
              </ion-col>
            </ion-row>

            <ion-row>
              <!-- Country -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Country *">
                  <v-field
                    name="country"
                    v-slot="{ field }"
                    v-model="property.country"
                    :rules="required"
                  >
                    <select v-bind="field" class="regular-select">
                      <option value="United States">United States</option>
                    </select>
                  </v-field>
                </base-input>
                <v-error-message name="country" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Timezone -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Timezone *">
                  <v-field
                    name="timezone"
                    v-slot="{ field }"
                    v-model="property.timezone"
                    :rules="required"
                  >
                    <select v-bind="field" class="regular-select">
                      <option value="America/New_York">
                        Americas - New York
                      </option>
                      <option value="America/Los_Angeles">
                        Americas - Los Angeles
                      </option>
                    </select>
                  </v-field>
                </base-input>
                <v-error-message name="timezone" class="error-message">
                </v-error-message>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-button type="submit" expand="block" class="margin-y-16">
            Save
          </ion-button>
        </base-card>
      </v-form>
    </div>
  </base-layout-two>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonInput, IonButton } from "@ionic/vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapActions } from "vuex";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonButton,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  data() {
    return {
      property: {},
      required: yup.string().required(),
    };
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    async onSubmit() {
      const config = {
        method: "post",
        url: `${process.env.VUE_APP_API}/properties`,
        data: this.property,
      };

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          this.property = response.data.property;

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Property Created", color: "secondary" });

          this.$router.replace(`/properties/${this.property.id}`);
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },
  },
};
</script>